<template>
  <v-container fluid id="about" class="my-16">
    <v-row class="align-center text-capitalize my-16">
      <v-col cols="12">
        <v-carousel cycle class="dark5--text text-h3" hide-delimiter-background :show-arrows="true"
          delimiter-icon="mdi-minus" :height="height" @change="handelCount(count++)">
          <v-carousel-item v-for="(slide, i) in $website_data.feature" :key="i" :reverse-transition="animation"
            :transition="animation">
            <v-row class="slider-row align-center justify-center">
              <v-col class="img-spinner order-md-1" cols="12" md="6" v-lazy-container="{ selector: 'img' }">
                <img :data-src="$serverUrl + slide.image" alt="" />
              </v-col>
              <v-col :class="
                $vuetify.rtl
                  ? 'content-spinner-rtl order-md-0'
                  : 'content-spinner order-md-0'
              " cols="12" md="6" >
                <h3 class="text-h4 font-weight-bold gray3--text mb-3" >
                  {{ count === -1 ? $website_data.feature[0].title : $website_data.feature[count].title }}
                </h3>
                <div class="text-body-1 font-weight-regular gray7--text text-subtitle-1"
                  v-html="count === -1 ? $website_data.feature[0].description : $website_data.feature[count].description">
                </div>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      count: -1,
      items: [
        {
          src: require("@/assets/Spiner/Spiner.png"),
        },
        {
          src: require("@/assets/Spiner/Spiner-1.png"),
        },
        {
          src: require("@/assets/Spiner/Spiner-2.png"),
        },
        {
          src: require("@/assets/Spiner/Spiner-3.png"),
        },
        {
          src: require("@/assets/Spiner/Spiner-4.png"),
        },
      ]
    };
  },
  methods: {
    handelCount: function () {
      if (this.count > this.items.length - 1) {
        this.count = 0;
      }
    }
  },
  computed: {
    animation() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return "scroll-x-transition";
      } else {
        return "expand-transition";
      }
    },
    height() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return "580";
      } else {
        // return "fit-content";
        return "350";
      }
    },
  }
  
};
</script>

<style lang="scss">
.v-responsive {
  overflow: visible !important;
}

.v-carousel__controls {
  bottom: 145px !important;
  top: 50%;
  background: transparent !important;
  left: -696px !important;
  transform: translateY(-50%) rotate(90deg);
}

.v-window__next {
  right: 85.9rem !important;
  top: 18rem !important;
  z-index: 205;
  transform: translateX(-100%) rotate(90deg);
}

.v-window__prev {
  left: 1.1rem !important;
  top: 1rem !important;
  transform: rotate(90deg);
  z-index: 205;
}

.v-application--is-rtl {
  .v-carousel__controls {
    bottom: 145px !important;
    top: 50%;
    background: transparent !important;
    right: -696px !important;
    transform: translateY(-50%) rotate(90deg);
  }

  .v-window__next {
    left: 90.4rem !important;
    top: 1.1rem !important;
    right: unset !important;
  }

  .v-window__prev {
    left: 87rem !important;
    top: 16.5rem !important;
  }
}

button.v-carousel__controls__item.v-btn.v-btn--icon.v-btn--round.theme--dark.v-size--small {
  color: #d2d1d4 !important;
}

i.v-icon.notranslate.mdi.mdi-power-on.theme--dark {
  font-size: 35px !important;
}

.v-carousel {
  overflow: visible;
}

@media (max-width: 1280px) {

  .v-window__prev,
  .v-window__next {
    display: none;
  }
}

.img-spinner {
  max-width: 691px;
}

.img-spinner img {
  width: 100%;
  max-height: 380px;
}

.content-spinner-rtl,
.content-spinner {
  max-width: 629px;
  padding: 25px 18px;
  position: relative;
  background: white;
  height: 414px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
}

@media(max-width:450px) {

  .content-spinner-rtl,
  .content-spinner {
    justify-content: flex-start;
  }
}

.content-spinner-rtl h3,
.content-spinner h3 {
  line-height: 1.6 !important;
  margin-bottom: 45px;
}

.content-spinner:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 93%;
  background: #ffde54;
  left: -10px;
  z-index: -5;
  border-radius: 6px;
  top: 50%;
  transform: translateY(-50%);
}

.content-spinner-rtl:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 93%;
  background: #ffde54;
  right: -10px;
  z-index: -5;
  border-radius: 6px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 450px) {
  .content-spinner {
    max-width: 371px;
    height: auto;
  }
}

.slider-row {
  position: relative;
}

.back-layer {
  background: grey;
  max-width: 874px;
  height: 546px;
  position: absolute;
  left: 0;
}
</style>
