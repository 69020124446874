import Vue from "vue"
import VueLazyload from "vue-lazyload"


// with options
const loadimage = require('@/assets/image 65.svg')
const errorimage = require('@/assets/image 65.svg')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 3
})