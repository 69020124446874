<template>
  <v-container fluid class="Our-clients my-16 mx-xs-0 mx-lg-16">
    <v-row class="align-center">
      <v-col cols="12" md="5"  data-aos="zoom-in">
        <div class="textContainer">
          <h4 class="text-h3 text-sm-h2 font-weight-bold gray3--text mb-6">
            {{ $t("our clients") }}
          </h4>
          <p class="text-h5 text-sm-h4 font-weight-bold gray7--text">
            {{ $t("who put their trust in us") }}
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="6"  data-aos="zoom-in">
        <div class="animationContainer">
          <div :class="$vuetify.rtl ? 'world-wide-layer-rtl' : 'world-wide-layer'">
            <img src="@/assets/world (2).svg" alt="" />
          </div>
          <div :class="$vuetify.rtl ? 'company-logo-rtl' : 'company-logo'">
            <div class="feed-logo" v-for="img in $website_data.customer" :key="img.id">
              <img :src="$serverUrl + img.image" :alt="img.title" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.animationContainer {
  position: relative;
  overflow: hidden;
  height: 485px;
}

.textContainer {
  max-width: 450px;
}

.company-logo-rtl,
.company-logo {
  div {
    width: 121px;
    height: 121px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
    top: -416px;
    position: relative;
  }


  .two,
  .three {
    background: white;
  }

  div {
    img {
      width: 78%;
    }
  }
}

.company-logo-rtl,
.company-logo {
  .feed-logo:nth-child(1) {
    left: 35px;
  }

  .feed-logo:nth-child(2) {
    left: 9px;
  }

  .feed-logo:nth-child(3) {
    left: 66px;
  }

  .feed-logo:nth-child(4) {
    left: 233px;
    top: -775px;
  }

  .feed-logo:nth-child(5) {
    left: 169px;
    top: -775px;
  }

  .feed-logo:nth-child(6) {
    left: 247px;
    top: -775px;
  }

  .feed-logo:nth-child(7) {
    left: 327px;
    top: -1033px;
    width: 139px;
    height: 139px;
  }

  .feed-logo:nth-child(8) {
    left: 442px;
    top: -1269px;
  }

  .feed-logo:nth-child(9) {
    left: 519px;
    top: -1266px;
  }

  .feed-logo:nth-child(10) {
    left: 409px;
    top: -1276px;
  }

  .feed-logo:nth-child(11) {
    left: 612px;
    top: -1622px;
  }

  .feed-logo:nth-child(12) {
    left: 662px;
    top: -1615px;
    width: 139px;
    height: 139px;
  }

  .feed-logo:nth-child(13) {
    left: 577px;
    top: -1634px;
  }

  .feed-logo:nth-child(14) {
    left: 818px;
    top: -1909px;
    width: 139px;
    height: 139px;
  }
}

.world-wide-layer {
  position: relative;
  left: 0;
  animation: moveWorledImg 10s infinite;
  animation-timing-function: linear;
  max-width: 650px;
}

.world-wide-layer-rtl {
  position: relative;
  left: 0;
  animation: moveWorledImg-rtl 10s infinite;
  animation-timing-function: linear;
  max-width: 650px;
}

@keyframes moveWorledImg {
  0% {
    left: 0;
  }

  50% {
    left: 85px;
  }

  100% {
    left: 0;
  }
}

@keyframes moveWorledImg-rtl {
  0% {
    left: 0;
  }

  50% {
    left: 85px;
  }

  100% {
    left: 0;
  }
}

.company-logo {
  height: 430px;
  position: relative;
  right: 0;
  animation: companyLogoImgs 10s infinite;
  animation-timing-function: linear;
}

.company-logo-rtl {
  @extend .company-logo;
  animation: companyLogoImgs-rtl 10s infinite;
}

@keyframes companyLogoImgs {
  0% {
    right: 0;
  }

  50% {
    right: 85px;
  }

  100% {
    right: 0;
  }
}

@keyframes companyLogoImgs-rtl {
  0% {
    right: 500px;
  }

  50% {
    right: 600px;
  }

  100% {
    right: 500px;
  }
}
</style>
