<template>
  <v-container fluid class="ease-access-section px-lg-16 my-16">
    <v-row class="rowEaseSection align-center">
      <v-col cols="12" md="6" >
        <div class="ease-access-content">
          <h4 class="text-h5 text-lg-h3 gray3--text font-weight-bold mb-8">
            {{pageCode.title}}
          </h4>
          <p class="text-body-2 text-lg-h4 gray7--text mb-8">
            {{pageCode.description}}
          </p>
          <div class="store-links mx-2">
            <a :href="links.title" target="_blank" data-aos="fade-up-left">
              <img src="@/assets/googlePlayStore.png" alt="Google Play Store" />
            </a>
            <v-spacer></v-spacer>
            <a :href="links.description" target="_blank" data-aos="fade-up-right">
              <img src="@/assets/appleStore.png" alt="Apple Store" />
            </a>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="6" >
        <div class="ease-access-img" data-aos="fade-up-right">
          <img class="back" src="@/assets/ease-access-back.svg" alt="" />
          <div class="easeImage_front" v-lazy-container="{ selector: 'img' }" data-aos="fade-up-left">
            <img class="front" :data-src="$serverUrl + pageCode.image" alt="" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EaseAccessSection",
  props: {
    'pageCode': Object,
    'links': Object
  }

};
</script>

<style lang="scss" scoped>
.ease-access-content {
  // background: url("@/assets/Vector RTL.svg");
  background-repeat: no-repeat;
}

@media (max-width: 450px) {
  .ease-access-content {
    height: 500px;
  }
}

.rowEaseSection p {
  line-height: 1.6;
}

.ease-access-img {
  max-width: 600px;
  position: relative;
  margin: 0 0 0 auto;
  height: 635px;
}

@media (max-width: 450px) {
  .ease-access-img {
    margin-top: -250px;
    height: 388px;
  }
}

.ease-access-img img {
  width: 100%;
  position: absolute;
}

.ease-access-img .front {
  top: 90px;
}

.store-links {
  display: flex;
  max-width: 516px;
  justify-content: space-between;
  align-items: center;
}

.store-links a:nth-child(1) {
  img {
    border: 1px solid black;
    border-radius: 15px;
  }
}

@media (max-width: 450px) {
  .store-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .store-links img {
    width: 164px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
