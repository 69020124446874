<template>
  <v-container class="formValidation">
    <v-btn @click="dialogForm = true" :disabled="selected.length > 0 ? false : true" max-width="600" height="100%"
      class="bt-trail gray2 white--text rounded-lg">
      {{ $t("Continue to get the free version")
      }}<v-icon class="icon-trial mx-2">
        {{ $vuetify.rtl ? "mdi-arrow-left" : "mdi-arrow-right" }}
      </v-icon>
    </v-btn>

    <v-row justify="center">
      <v-dialog v-model="dialogForm" max-width="900px">
        <v-card>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-card-actions class="justify-end pb-0">
              <div class="close" @click="dialogForm = false">
                <img src="@/assets/Basics/x-circle.svg" alt="close Form" width="30" />
              </div>
            </v-card-actions>
            <v-card-title class="pt-0">
              <span class="text-h5 font-weight-bold px-0">{{
              $t("Get your free copy")
              }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" lg="6" md="6">
                    <v-text-field v-model="form.company_name" :rules="nameRules" :label="$i18n.t('The Company name*')"
                      required>
                    </v-text-field>
                    <v-text-field v-model="form.name" :rules="nameRules" :label="$i18n.t('User name*')" required>
                    </v-text-field>
                    <v-text-field v-model="form.email" :rules="emailRules" :label="$i18n.t('E-mail*')" required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" md="6">
                    <v-text-field v-model="form.register_commercial" :rules="regRules"
                      :label="this.$i18n.t('Commercial Registration No*')" required type="number"></v-text-field>
                    <v-text-field v-model="form.phone" :label="this.$i18n.t('Mobile Number*')" :rules="mobileRules"
                      required class="textImage">
                      <template #append-outer>
                        <div :class="$vuetify.rtl ? 'flag-rtl' : 'flag'">
                          <img src="@/assets/saudi.png" alt="" />
                        </div>
                      </template>
                    </v-text-field>
                    <div class="get-it d-flex justify-end align-center">
                      <v-btn @click.prevent="validate" :disabled="!valid" type="submit"
                        class="yellow3 gray3--text text-h6 mt-1 font-weight-bold">
                        {{ $t('Get it now') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <ThankYouMassege :openlog="dialogThankyou" :pageCode="pageCode" :closePopup="receiveChildEvent" :freeTrailPage="freeTrailPage" :support="customer_support" />

  </v-container>
</template>

<script>
import ThankYouMassege from './ThankYouMassege.vue';
export default {
  data() {
    return {
      valid: false,
      dialogForm: false,
      dialogThankyou: false,
      sendData: false,
      freeTrailPage: true,
      form: {
        company_name: null,
        name: null,
        phone: null,
        register_commercial: null,
        email: null,
        services_id: []
      },
      regRules: [
        (v) => !!v || this.$i18n.t("Commercial Registration Number is required"),
      ],
      nameRules: [(v) => !!v || this.$i18n.t('Name is required')],
      emailRules: [
        (v) => !!v || this.$i18n.t("E-mail is required"),
        (v) => /.+@.+/.test(v) || this.$i18n.t("E-mail must be valid"),
      ],
      mobileRules: [(v) => !!v || this.$i18n.t("Mobile Number is required")],
    };
  },
  props: {
    selected: Array,
    pageCode: Object,
    benefits: Array,
    customer_support: Object
  },
  methods: {
    filterServicesId() {
      this.benefits.forEach(element => {
        this.selected.forEach(elemnt_selected => {
          if (element.title === elemnt_selected) {
            this.form.services_id.push(element.id)
          }
        })
      })
    },
    validate() {
      if (this.$refs.form.validate()) {
        (this.dialogForm = false),
          (this.sendData = true),
          (this.dialogThankyou = true),
          this.filterServicesId()
        this.$api.POST_METHOD('getfreetrials', this.form)
        setTimeout(() => this.$router.push("/"), 2000);
      }
    },
    receiveChildEvent(){
      return this.dialogThankyou = false
    }
  },
  computed: {},
  components: { ThankYouMassege },
};
</script>

<style lang="scss" scoped>
.container.formValidation {
  width: 100%;
  height: 85px;
}

.textImage {
  position: relative;
}

.flag {
  top: 0;
  right: 10px;
  position: absolute;
}

.flag-rtl {
  top: 0;
  left: 10px;
  position: absolute;
}

.close {
  cursor: pointer;
}

.get-it button {
  width: 190px;
  height: 48px !important;
}

.close {
  cursor: pointer;
  text-align: end;
  position: relative;
  right: 0;
  top: 14px;
}

.cartText {
  line-height: 1.9;
}
</style>
