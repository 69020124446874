<template>
  <v-app>
    <v-main v-if="!websiteLoading">
      <AppBar />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/ui/AppBar.vue";

export default {
  name: "App",
  data() {
    return {
      websiteLoading: true,
    }
  },
  components: {
    AppBar,
  },
  methods: {

  },
  mounted() {
    this.$api.GET_WEBSITE_DATA().then(() => {
      this.websiteLoading = this.$website_loading;
    })

  }
};
</script>
<style lang="scss">
@import "./scss/variables.scss";
@import "./scss/core.scss";
</style>
