<template>
  <v-container fluid class="free-trial my-16 mx-0 mx-lg-8" v-if="lodingData">
    <div class="effectBox-container">
      <img src="@/assets/roundEffect-free-trail.png" alt="" class="roundEffect" />
      <img src="@/assets/backeffect-free-trail.png" alt="" class="bigEffect" />
      <div class="glassEffect"></div>
    </div>
    <v-row class="text-container align-center flex-column">
      <h1 class="font-weight-bold gray3--text text-h4 text-md-h2 mb-5">
        {{this.heading.title}}
      </h1>
      <div class="content pa-4 pa-xl-0">
        <div class="gray6--text ma-2" v-html="heading.description">
        </div>
      </div>
    </v-row>
    <v-row class="mt-12">
      <v-col cols="12" md="7" lg="7">
        <div class="benefitsContainer">
          <div class="benefitBox" v-for="(box, i) in benefits" :key="i">
            <v-checkbox color="yellow4" v-model="selected" :label="box.title" :value="box.title" />
            <p class="gray7--text">{{ box.description }}</p>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="5" lg="5">
        <v-row class="checkOut pa-2 pa-md-8 pa-lg-11 ma-md-auto text-center mx-1">
          <v-col cols="12" class="selected-item" v-for="select in selected" :key="select">
            <p class="font-weight-bold text-h6 ma-0 pa-3">{{ select }} </p>
            <img src="@/assets/icons/checkboxes.png" alt="" />
          </v-col>
          <v-col cols="12" class="freeTrial_btn ">
            <form-validation-free-trial :selected="selected" :pageCode="thankPopup" :benefits="$website_data.service"
              :customer_support="customer_support" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormValidationFreeTrial from "@/components/ui/FormValidationFreeTrial.vue";
export default {
  name: "FreeTrialPage",
  data: () => ({
    selected: [],
    benefits: null,
    heading: null,
    thankPopup: null,
    customer_support: null,
    lodingData: false
  }),
  components: {
    FormValidationFreeTrial,
  },
  methods: {
    getData() {
      this.benefits = this.$website_data.service

      this.$website_data.home.forEach(element => {
        switch (element.page_code) {
          case ('free_trial'):
            this.heading = element
            break;
          case ('pop_up_contactus'):
            this.thankPopup = element
            break;
          case ('customer_support'):
            this.customer_support = element
            break;
        }

        // remove undefined error
        if (!this.$website_loading) {
          this.lodingData = true
        }
      })
    },
  },
  mounted() {
    this.getData()
  }
};
</script>

<style lang="scss">
.free-trial .benefitsContainer {
  position: relative;
  z-index: 1;
}

.free-trial .glassEffect {
  background: linear-gradient(270.78deg,
      rgba(255, 254, 254, 0.58) -18.71%,
      rgba(255, 254, 254, 0.9) -18.7%,
      rgba(255, 255, 255, 0) 138.44%);
  -webkit-backdrop-filter: blur(183px);
  backdrop-filter: blur(183px);
  width: 732px;
  height: 946px;
  position: absolute;
  top: 161px;
  left: -60px;
}

.free-trial img.bigEffect {
  position: absolute;
  left: 0;
  top: 93px;
  width: 660px;
}

.free-trial img.roundEffect {
  position: absolute;
  left: 432px;
  top: 97px;
  z-index: 1;
}

.free-trial .content {
  max-width: 780px;
}

.free-trial .content {
  li {
    list-style: disc;
  }
}

.free-trial .text-container {
  position: relative;
}

.free-trial .row {
  position: relative;
  z-index: 55;
}

.number {
  font-weight: 700 !important;
  color: #0F0D15 !important;
}

.free-trial .selected-item {
  max-width: 309px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin: auto;
  border-top: 1px solid #8080803b;
}

.free-trial .selected-item:nth-child(1) {
  border-top: none !important;
}

@media (max-width: 450px) {
  .free-trial .selected-item {
    max-width: 317px;
  }

  .checkOut button {
    height: 55px !important;
  }
}

.free-trial .benefitBox {
  background: #ffffff;
  border: 1px solid #edeff2;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0px 17px 34px rgb(0 0 0 / 10%);
}

.free-trial .checkOut {
  background: #ffffff;
  box-shadow: 0px 17px 34px rgb(0 0 0 / 10%);
  border-radius: 5px;
  max-width: 440px;
  // height: 99px;
}

@media (max-width: 1023px) {
  .effectBox-container {
    display: none;
  }
}

.free-trial label.v-label.theme--light {
  color: #0f2137;
  font-size: 20px;
  font-weight: 700;
}

p.free_description {
  font-weight: 600;
}

ul.free_list {
  font-weight: 700;
  color: #4A4754;
}
</style>
