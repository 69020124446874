<template>
  <v-container fluid class="px-4 px-md-5 px-lg-16">
    <div class="aboutEffectOne"></div>
    <div class="aboutEffectTwo"></div>
    <v-row class="aboutContainer align-center">
      <v-col cols="12" md="5" lg="6">
        <div class="about-row">
          <div class="logo">
            <img src="@/assets/icons/about-logo.svg" alt="" />
          </div>
          <p class="gray3--text">
            {{pageCode.description}}
          </p>

          <div class="social d-none d-md-flex">
            <a v-for="social in $website_data.icon" :key="social.id" :href="social.links" target="_blank"><img
                :src="$serverUrl + social.icons" alt="" height="20px" /></a>
          </div>
        </div>
      </v-col>
      <v-col cols="3" md="2" lg="2">
        <div class="links-row d-flex flex-column">
          <h5 class="gray3--text font-weight-black">{{ $t("Links") }}</h5>
          <a class="gray3--text" href="/"> {{ $t("Home") }}</a>
          <a class="gray3--text" href="/">{{ $t("About us") }}</a>
          <a class="gray3--text" href="#features">{{ $t("Features") }}</a>
          <a class="gray3--text" href="#contact-us"> {{ $t("Contact Us") }}</a>
        </div>
      </v-col>
      <v-col cols="9" md="5" lg="4">
        <div class="contact-us-row d-flex flex-column">
          <!-- <h5 class="gray3--text font-weight-black">Contact Us</h5> -->
          <h5 class="gray3--text font-weight-black">{{$t("Contact Us")}}</h5>

          <!-- <span class="font-weight-bold text-Roboto text-md-body-1">Jeddah phone - Mecca - Taif: 966555507235</span>
          <span class="font-weight-bold text-Roboto text-md-body-1">Riyadh - Dammam - Khobar phone: 966555507235</span>
          <span class="font-weight-bold text-Roboto text-md-body-1">Email: info@QaratERP.com</span>
          <span class="font-weight-bold text-Roboto text-md-body-1">What`s App: 966555507235</span> -->


          <span class="font-weight-bold text-Roboto text-md-body-1">{{contact_us[0].description}}</span>
          <span class="font-weight-bold text-Roboto text-md-body-1">{{contact_us[1].description}}</span>
          <span class="font-weight-bold text-Roboto text-md-body-1">{{contact_us[2].description}}</span>
          <span class="font-weight-bold text-Roboto text-md-body-1">{{contact_us[3].description}}</span>
        </div>
      </v-col>
      <div class="social mobile d-flex d-md-none">
        <a v-for="social in $website_data.icon" :key="social.id" :href="social.links" target="_blank"><img
            :src="$serverUrl + social.icons" alt="" height="20px" /></a>
      </div>
    </v-row>
    <v-row class="justify-center my-10">
      <footer class="gray7--text text-caption pa-2 text-center">
        2022 © قيراط برنامج محاسبة لإدارة محلات الذهب والمجوهرات. | All Rights
        Reserved | Designed & Developed By Cloud Secrets KSA
      </footer>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    'pageCode': Object,
    'contact_us': Array,
  },
  
};
</script>

<style lang="scss" scoped>
.aboutEffectOne {
  background: url("@/assets/aboutEffectOne.svg");
  position: absolute;
  width: 272px;
  height: 500px;
  right: 0;
  background-repeat: no-repeat;
}

.aboutEffectTwo {
  background: url("@/assets/aboutEffectTwo.svg");
  position: absolute;
  width: 272px;
  height: 500px;
  left: 0;
  background-repeat: no-repeat;
}

@media (max-width: 600px) {
  .aboutEffectOne {
    // background: url("@/assets/aboutEffectOneMobile.png");
    // background-repeat: no-repeat;
    // bottom: 677px;
    display: none;
  }

  .aboutEffectTwo {
    background: url("@/assets/aboutEffectTwoMobile.png");
    background-repeat: no-repeat;
  }

  .v-application .align-center {
    align-items: flex-start !important;
  }
}

.aboutContainer {
  position: relative;
  z-index: 150;
}

.about-row .logo img {
  max-height: 147px;
}

.social a {
  margin-right: 15px;
}

.contact-us-row,
.links-row {
  line-height: 1.9 !important;
}

p.gray3--text {
  max-width: 548px;
}
</style>
