<template>
  <v-container fluid class="company-assets px-lg-16 mt-lg-13 mt-md-13 text-capitalize my-16" >
    <img class="parallax" src="@/assets/parallax.png" />
    <v-card class="container-assets elevation-0">
      <v-row class="row_assets pa-7">
        <v-col data-aos="fade-down" cols="12" md="4" lg="4" class="column_assets d-flex align-center justify-md-center"
          v-for="asset in $website_data.company_asset" :key="asset.id">
          <div class="details pr-3">
            <v-col cols="12" class="gray5--text text-h5 font-weight-light">{{asset.title}}</v-col>
            <v-col cols="12" class="text-h2 font-weight-bold mt-5 mb-0">{{asset.number}}</v-col>
          </div>

          <div class="pt-5">
            <span class="font-weight-light gray5--text text-h6">{{asset.sub_title}}</span>
            <p class="font-weight-black text-h5 mt-4 mb-0">{{asset.description}}</p>
          </div>
        </v-col>

      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CompanyAssets",
};
</script>

<style lang="scss" scoped>
.separator {
  background: blue;
  width: 2px;
  height: 150px;
}

.container-assets {
  border-radius: 45px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(73px);
  -webkit-backdrop-filter: blur(73px);
  position: relative;
  z-index: 100;
}

@media(max-width:600px) {
  .container-assets {
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;

  }
}

.v-application--is-rtl {
  .column_assets::before {
    content: "";
    left: 20% !important;
  }
}

.row_assets .column_assets:nth-child(7)::before,
.row_assets .column_assets:nth-child(4)::before,
.row_assets .column_assets:nth-child(1)::before {
  display: none;
}

.column_assets::before {
  content: "";
  width: 1px;
  background: rgba(0, 0, 0, 0.1843137255);
  height: 150px;
  left: -7% !important;
  position: relative;
}

@media(max-width:600px) {
  .column_assets::before {
    display: none;
  }

}

.parallax {
  position: absolute;
  width: 86%;
  height: 150px;
}

@media (max-width: 450px) {
  .parallax {
    display: none;
  }

  .container-assets {
    margin-top: -140px;
  }
}
</style>
