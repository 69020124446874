import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import slide from "./plugins/slide"
import i18n from './plugins/i18n'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import API from './plugins/API'
import lazyload from './plugins/lazyload'
import meta from './plugins/meta-tags'

import animation from './plugins/animation'
Vue.config.productionTip = false
Vue.use(API)

new Vue({
  router,
  store,
  vuetify,
  slide,
  i18n,
  lazyload,
  meta,
  animation,

  render: h => h(App)
}).$mount('#app')