<template>
  <v-container class="login-popup pa-0" >
    <v-btn @click="dialog = true" depressed class="rounded-pill yellow13--text yellow11 font-weight-bold" height="50"
      width="145" max-width="100%">
      {{ $t("Log in") }}
    </v-btn>
    <v-dialog v-model="dialog" max-width="1150" class="rounded-xl" v-if="lodingData">
      <v-card class="card-login px-1 px-md-16" height="600">
        <v-card-actions class="justify-end">
          <div class="close" @click="dialog = false">
            <img src="@/assets/Basics/x-circle.svg" alt="close Form" width="30" />
          </div>
        </v-card-actions>
        <v-card-title class="login__title justify-center font-weight-bold text-h6 text-md-h5 gray4--text">
          {{pageCode[0].title}}
        </v-card-title>
        <v-row class="loginRow text-center justify-center gray4--text text-subtitle-1 text-md-h5 mt-7">
          <v-col class="column-login ma-5" cols="10" md="5">
            <div class="login-content">
              <p class="font-weight-bold">{{pageCode[1].description}}</p>
              <v-btn class="rounded-xl yellow11 yellow0--text mb-16 px-10 py-5 mt-5">sign in</v-btn>
              <div class="login-image">
                <img src="@/assets/image 62.png" alt="" />
              </div>
            </div>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
          <v-col class="column-login ma-5" cols="10" md="5">
            <div class="login-content">
              <p class="font-weight-bold">
                {{pageCode[2].description}}
              </p>
              <v-btn class="rounded-xl yellow11 yellow0--text mb-16 px-10 py-5 mt-5">sign in</v-btn>
              <div class="login-image">
                <img src="@/assets/image 60.png" alt="" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      lodingData: false,
    };
  },
  methods: {
    getData() {
      if (!this.$website_loading) {
        this.lodingData = true
      }
    }
  },
  props: {
    'pageCode': Array,
  },
  mounted() {
    this.getData()
  }
};
</script>

<style lang="scss" scoped>
.column-login {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(204px);
  border-radius: 20px 20px 0px 0px;
  transition: all 0.3s ease-in-out;
}

.column-login:hover {
  background: rgb(255 255 255 / 73%);

  .login-image {
    transform: scale(0.9);
  }
}

@media (max-width: 960px) {
  .column-login {
    background: #fff8dd;
  }

  .column-login:hover {
    background: #ffefaa;
  }
}

.card-login {
  position: relative;
  overflow: hidden;
  z-index: 200;
}

@media (max-width: 960px) {
  .card-login {
    height: 100% !important;
  }
}

.loginRow {
  position: relative;
  height: 100%;
}

.card-login::before {
  content: url("@/assets/path470.png");
  position: absolute;
  right: 0;
  bottom: -12px;
  z-index: -1;
}

.card-login::after {
  content: url("@/assets/path471.png");
  position: absolute;
  right: 0;
  top: -12px;
  z-index: -1;
}

@media (max-width: 960px) {

  .card-login::before,
  .card-login::after {
    display: none;
  }
}

.login-image {
  height: 217px;
  transition: all 0.3s ease-in-out;
}

.login-image img {
  height: 100%;
  max-width: 100%;
}

.close {
  position: relative;
  cursor: pointer;
  width: 50px;
  top: 60px;
}

@media (max-width: 600px) {
  .close {
    position: absolute;
    cursor: pointer;
    width: 29px;
    top: 9px;
    left: 15px;
  }

  .login__title {
    margin-top: 15px;
  }
}

.close img {
  width: 100%;
}
</style>
