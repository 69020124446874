import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/Website/HomePage'
import FreeTrialPage from '../views/Website/FreeTrialPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/free-trial',
    name: 'FreeTrialPage',
    component: FreeTrialPage
  },
  {
    path: '*',
    component: HomePage
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
