<template>
  <v-dialog v-model="landing_dialog" max-width="1100">
    <v-card class="popup_cart" height="530">
      <v-container class="px-6 px-md-16">
          <v-form v-model="valid" ref="form" class="tryQaratPopup">
          <v-card-actions class="justify-end">
            <div class="close" @click="landing_dialog = false">
              <img src="@/assets/Basics/x-circle.svg" alt="close Form" width="30" />
            </div>
          </v-card-actions>
          <v-row class="justify-space-around">
            <v-col cols="12" md="5">
              <v-card-title
                class="font-weight-bold gray7--text text-h4 text-md-h3 mb-10 justify-center justify-md-start">
                {{ $t("Try Qarats now") }}
              </v-card-title>
              <v-text-field class="text-capitalize rounded-lg" v-model="form.name" :label="$i18n.t('write your name')"
                :rules="nameRule" required outlined background-color="gray14" color="yellow1">
              </v-text-field>

              <v-text-field class="flag-container rounded-lg" v-model="form.phone" :label="$i18n.t('Mobile number')"
                :rules="numRules" required outlined background-color="gray14" color="yellow1">
                <template #append-outer>
                  <div :class="$vuetify.rtl ? 'flag-rtl' : 'flag'">
                    <img src="@/assets/saudi.png" alt="" />
                  </div>
                </template>
              </v-text-field>
              <v-btn class="try_btn yellow3 pa-6 font-weight-black rounded-lg text-h6" width="195" width-xs="500"
                :disabled="!valid" @click.prevent="checkForm" type="submit">
                {{ $t("Connect with us") }}</v-btn>
            </v-col>
            <v-col cols="12" md="5" class="d-none d-md-flex">
              <div class="try-image">
                <img src="@/assets/tryQarats.png" alt="try qarats" />
              </div>
            </v-col>
          </v-row>
        </v-form>
        </v-container>
      </v-card>
      <ThankYouMassege :openlog="dialogThankyou" :pageCode="pageCode" :closePopup="receiveChildEvent" />
    </v-dialog>
</template>

<script>
import ThankYouMassege from './ThankYouMassege.vue';
export default {
  data() {
    return {
      landing_dialog: false,
      dialogThankyou: false,
      valid: false,
      sendData: false,
      form: {
        name: null,
        phone: null,
      },
      nameRule: [(v) => !!v || this.$i18n.t("Name is required")],
      numRules: [(v) => !!v || this.$i18n.t("Mobile Number is required")],
    };
  },
  methods: {
    openDialog() {
      return (this.landing_dialog = true);
    },
    checkForm() {
      this.dialogThankyou = true;
      setTimeout(() => { window.location.reload(true); }, 2000);
      this.$api.POST_METHOD("contacts", this.form);
    },
    receiveChildEvent() {
      return this.dialogThankyou = false
    }
  },
  mounted() {
    setTimeout(() => this.openDialog(), 3000);
  },
  props: {
    "pageCode": Object,
  },
  components: { ThankYouMassege }
};
</script>

<style lang="scss" scoped>
.close {
  cursor: pointer;
  width: 45px;
  height: 45px;
}

.close_thankYou {
  cursor: pointer;
  position: relative;
  top: 7px;
}

@media (max-width: 450px) {
  .close {
    width: 30px;
    height: 30px;
  }
}

.close img {
  width: 100%;
}

.container {
  height: 100%;
  position: relative;
}

.row {
  align-items: center;
}

@media(max-width:600px) {
  .popup_cart {
    height: 460px !important;
  }

}

.close__container {
  position: absolute;
  right: 9px;
  top: 40px;
}

.try-image img {
  width: 100%;
}

.try_btn:hover {
  background-color: black !important;
  color: #ffde54;
  transition: all 0.3s;
}

@media (max-width: 450px) {
  .numberInput.d-flex {
    width: 100%;
  }

  .inputs button {
    width: 100%;
  }

  .try_btn {
    width: 100% !important;
  }
}

.flag-container {
  position: relative;
}

.flag {
  top: 4px;
  right: 10px;
  position: absolute;
}

.flag-rtl {
  top: 4px;
  left: 10px;
  position: absolute;
}
</style>
