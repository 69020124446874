<template>
  <div v-if="lodingData">
    <try-qarat-popup :pageCode="pop_up_contactus2" />
    <landing-section :pageCode="main_section" />
    <company-assets />
    <benefits-section :pageCode="goals" />
    <carousel-section />
    <ease-access-section :pageCode="eseay_access" :links="links" />
    <our-clients-section />
    <customers-feed-back />
    <questions-section :pageCode="questions" :thankYouData="pop_up_contactus2" />
    <about-section :pageCode="footer" :contact_us="contact_us" />
  </div>
</template>

<script>
import LandingSection from "@/components/ui/LandingSection.vue";
import CompanyAssets from "@/components/ui/CompanyAssets.vue";
import BenefitsSection from "@/components/ui/BenefitsSection.vue";
import CarouselSection from "@/components/ui/CarouselSection.vue";
import EaseAccessSection from "@/components/ui/EaseAccessSection.vue";
import OurClientsSection from "@/components/ui/OurClientsSection.vue";
import CustomersFeedBack from "@/components/ui/CustomersFeedBack.vue";
import QuestionsSection from "@/components/ui/QuestionsSection.vue";
import AboutSection from "@/components/ui/AboutSection.vue";
import TryQaratPopup from "@/components/ui/TryQaratPopup.vue";

export default {
  name: "HomePage",

  components: {
    LandingSection,
    CompanyAssets,
    BenefitsSection,
    CarouselSection,
    EaseAccessSection,
    OurClientsSection,
    CustomersFeedBack,
    QuestionsSection,
    AboutSection,
    TryQaratPopup,
  },
  data() {
    return {
      main_section: null,
      footer: null,
      questions: null,
      goals: null,
      eseay_access: null,
      pop_up_contactus2: null,
      links: null,
      contact_us: [],
      lodingData: false,
    }
  },
  methods: {
    getData() {
      //get all static data in Home API
      this.websiteData = this.$website_data;
      this.$website_data.home.forEach(element => {
        switch (element.page_code) {
          case "main_section":
            this.main_section = element;
            break;
          case "footer":
            this.footer = element;
            break;
          case "questions":
            this.questions = element;
            break;
          case "goals":
            this.goals = element;
            break;
          case "eseay_access":
            this.eseay_access = element;
            break;
          case "contact_footer":
            this.contact_us.push(element);
            break;
          case "pop_up_contactus2":
            this.pop_up_contactus2 = element;
            break;
          case "links":
            this.links = element;
            break;
          case "title_home":
            document.title = element.title;
            break;
        }
      });
      
      // remove undefined error
      if (!this.$website_loading) {
        this.lodingData = true
      }
    },
    setMeta(){
      // Get all meta tags
      const head = document.getElementById('head_index')
      this.$website_data.meta.forEach(e => {
        const meta = document.createElement('meta')
        meta.name = e.name
        meta.content = e.content
        head.prepend(meta)
      })
    }

  },
  mounted() {
    this.getData()
    this.setMeta()
  },

}
</script>
