<template>
  <v-container fluid class="px-lg-16">
    <v-row class="app-bar" justify="space-between" align="center">
      <v-col cols="auto" sm="2" md="auto" lg="2" class="d-flex align-center">
        <a href="/" class="logo me-3">
          <img :src="$serverUrl + logo" alt="logo" />
        </a>
        <v-btn depressed icon @click="changeLange"
          class="lang gray5--text font-weight-bold text-sm-body-2 white ml-n2 rounded-circle text-uppercase">
          {{ lang }}
        </v-btn>
      </v-col>
      <v-col cols="auto" sm="7" md="auto" lg="8">
        <v-row class="justify-center" align="center">
          <v-col cols="8" class="d-none d-sm-flex justify-space-between px-0 align-center">
            <v-btn depressed href="/" class="rounded-pill yellow13--text gray12 px-5 py-6 font-weight-bold text-h6">
              {{ $t("Home") }}
            </v-btn>
            <v-btn text class="text-h6 px-5 py-6 rounded-pill gray5--text font-weight-bold" href="#about">
              {{ $t("About us") }}</v-btn>
            <v-btn text class="text-h6 px-5 py-6 rounded-pill gray5--text font-weight-bold" href="#features">
              {{ $t("Features") }}
            </v-btn>
            <v-btn text class="text-h6 px-5 py-6 rounded-pill gray5--text font-weight-bold" href="#contact-us">
              {{ $t("Contact Us") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" sm="auto" md="auto" lg="2">
        <LoginPopup :pageCode="login" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginPopup from "./LoginPopup.vue";
const getLocal = localStorage.getItem("language");

export default {
  data: () => ({
    lang: getLocal && getLocal === "en" ? "ar" : "en",
    logo: null,
    login: [],
  }),
  methods: {
    changeLange: function () {
      if (this.lang === "en") {
        this.$i18n.locale = localStorage.setItem("language", "en");
        window.location.reload(true);
      } else {
        this.$i18n.locale = localStorage.setItem("language", "ar");
        window.location.reload(true);
      }
    },
    getData() {
      this.$website_data.home.forEach(element => {
        switch (element.page_code) {
          case 'logo':
            this.logo = element.image;
            break;
          case 'login_popup':
            this.login.push(element);
            break;
        }
      })
    },
    changeRTL() {
      // handel direction in first time open site
      if (getLocal) {
        if (getLocal === 'en') {
          this.$vuetify.rtl = false
        }
      } else {
        this.$vuetify.rtl = true
      }
    }
  }
  ,
  mounted: function () {
    this.getData()
    this.changeRTL()
  },
  components: { LoginPopup },
}

</script>


<style lang="scss" scoped>
  .logo img {
    max-height: 68px;
  }
  
  nav {
    width: 636px;
  }
  
  nav ul {
    width: 485px;
  }
  </style>