<template>
  <v-container fluid class="questions-section my-16">
    <div class="backEffectOne"></div>
    <div class="backEffectTwo"></div>
    <v-row class="questions-row align-center justify-center px-lg-16">
      <v-col cols="12" lg="4">
        <div>
          <h4 class="text-h5 text-md-h4 text-lg-h3 font-weight-bold mb-4" data-aos="fade-left">
            {{pageCode.title}}
          </h4>
          <p class="gray6--text" data-aos="fade-left">
            {{pageCode.description}}
          </p>
          <v-btn class="yellow3 pa-6 font-weight-bold rounded-xl mt-3" width="175" href="#contact-us"
            data-aos="fade-left">
            {{ $t("Connect with us") }}</v-btn>
        </div>
      </v-col>
      <v-col cols="12" lg="8">
        <div>
          <v-expansion-panels focusable data-aos="fade-right">
            <v-expansion-panel v-for="(data, i) in $website_data.question" :key="i">
              <v-expansion-panel-header class="text-h6 font-weight-bold">
                {{data.Question}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{data.answer}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="contact-us" id="contact-us">
          <v-form v-model="valid" ref="form">
            <div class="inputs d-flex">
              <v-text-field class="text-capitalize rounded-lg" required outlined background-color="gray14"
                color="yellow1" v-model="form.name" :label="$i18n.t('write your name')" :rules="nameRule">
              </v-text-field>

              <v-text-field class="flag-container rounded-lg" required outlined background-color="gray14"
                color="yellow1" v-model="form.phone" :label="$i18n.t('Mobile number')" :rules="numRules">
                <template #append-outer>
                  <div :class="$vuetify.rtl ? 'flag-rtl' : 'flag'">
                    <img src="@/assets/saudi.png" alt="" />
                  </div>
                </template>
              </v-text-field>

              <v-btn class="try_btn yellow3 pa-6 font-weight-black rounded-lg text-h5" width="212" height="56"
                :disabled="!valid" @click.prevent="sendData" type="submit">
                {{ $t("Connect with us") }}</v-btn>
            </div>
          </v-form>
        </div>
        <ThankYouMassege :openlog="dialogThankyou" :pageCode="thankYouData" :closePopup="receiveChildEvent" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ThankYouMassege from './ThankYouMassege.vue';
export default {
  props: {
    "pageCode": Object,
    "thankYouData": Object,
  },
  data() {
    return {
      dialogThankyou: false,
      valid: false,
      reciveData: [],
      form: {
        name: null,
        phone: null,
      },
      nameRule: [(v) => !!v || this.$i18n.t("Name is required")],
      numRules: [(v) => !!v || this.$i18n.t("Mobile Number is required")],
    };
  },
  methods: {
    sendData() {
      this.dialogThankyou = true
      this.$api.POST_METHOD("contacts", this.form);
      this.refresh()
    },
    refresh() {
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    },
    receiveChildEvent() {
      return this.dialogThankyou = false
    }
  },
  components: { ThankYouMassege }
};
</script>

<style lang="scss">
.questions-section .backEffectOne {
  background: url("@/assets/Ellipse 128.png");
  width: 451px;
  height: 482px;
  position: absolute;
  right: 67px;
}

.questions-section .backEffectTwo {
  background: url("@/assets/Ellipse 129.png");
  width: 358px;
  height: 483px;
  position: absolute;
  left: 60px;
}

@media (max-width: 1025px) {

  .questions-section .questions-row,
  .questions-section .backEffectOne,
  .questions-section .backEffectTwo {
    display: none;
  }
}

.questions-section .questions-row {
  background: rgba(255, 255, 255, 0.74);
  backdrop-filter: blur(212px);
  // height: 770px;
  height: auto;
}

.questions-section .questions-row p {
  width: 312px;
  line-height: 1.8;
}

.questions-section .v-expansion-panel {
  background: #ffffff;
  border: 1px solid #edeff2;
  border-radius: 5px;
  margin: 4px 0;
}

.questions-section .v-expansion-panel::before {
  box-shadow: none !important;
}

.questions-section .v-expansion-panel::after {
  border-color: transparent !important;
}

.questions-section .v-expansion-panel-content__wrap {
  color: #343d48;
  padding: 15px !important;
}

.questions-section .contact-us {
  .inputs {
    box-shadow: 0px 2px 41px rgba(0, 0, 0, 0.09);
    max-width: 923px;
    border-radius: 23px;
    margin: auto;
    flex-wrap: wrap;
    gap: 10px;
    padding: 49px 19px 25px 19px;
    z-index: 72;
    position: relative;
  }
}

.contact-us:target .inputs {
  box-shadow: 0px 2px 41px rgb(255 222 84 / 28%) !important;

}



.questions-section .numberInput {
  background: #eff6ff;
  border-radius: 9px;
  padding: 0 5px;
}

.questions-section .inputs button:hover {
  background-color: black !important;
  color: #ffde54;
  transition: all 0.3s;
}

@media (max-width: 450px) {
  .questions-section .numberInput.d-flex {
    width: 100%;
  }

  .questions-section .inputs button {
    width: 100%;
  }
}

.flag-container {
  position: relative;
}

.flag {
  top: 4px;
  right: 10px;
  position: absolute;
}

.flag-rtl {
  top: 4px;
  left: 10px;
  position: absolute;
}

.close_thankYou {
  cursor: pointer;
}
</style>
