
<template>
   <v-dialog v-model="openlog" max-width="400">
      <v-card class="rounded-xl text-capitalize elevation-0">
        <v-card-actions class="close_thankYou justify-end ">
          <div @click="handelClose">
            <img src="@/assets/Basics/x.png" alt="close Form" width="14" />
          </div>
        </v-card-actions>

        <v-card-title class="text-h5 text-center justify-center font-weight-bold text-h5">
          {{this.pageCode.title}}
        </v-card-title>
        <v-card-title class="justify-center">
          <div>
            <img :src="$serverUrl + pageCode.image" alt="" />
          </div>
        </v-card-title>
        <v-card-text class="cartText gray7--text" v-html="pageCode.description"></v-card-text>
        <v-card-text v-if="freeTrailPage">
          <a class="text-decoration-underline blue1--text" :href=" 'mailto:'+support.title">Customer
            support</a>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {

  props: {
    pageCode : Object,
    support: Object,
    openlog : Boolean,
    freeTrailPage : Boolean,
    closePopup : Function
  },
  methods:{
    handelClose(){
      return this.closePopup()
    }
  }
};
</script>

<style lang="scss" scoped>

.close_thankYou {
  cursor: pointer;
  position: relative;
  top: 7px;
}



</style>
