<template>
  <div id="example" class="my-16 pb-lg-16 px-lg-16" >
    <div class="backEffect"></div>
    <div class="title px-5 px-lg-0 gray8--text font-weight-bold text-lg-h2 text-h4 my-16" data-aos="fade-left">
      {{$t("Our users' opinions")}}
    </div>
    <carousel-3d  :autoplay="true" :autoplay-timeout="1500" :perspective="0" :space="500"
      :display="3" :width="400">
      <slide v-for="(slide, i) in $website_data.reviews" :index="i" :key="i"  >
        <v-container >
          <div class="box d-flex align-center text-center" data-aos="fade-up" >
            <div class="avatar"><img :src="$serverUrl + slide.image" alt="" /></div>
            <div class="content">
              <h5 class="store-name">
                {{slide.name}}
              </h5>
              <span class="customer-name">
                {{slide.company_name}}
              </span>
              <p class="feedBack">
                {{slide.description}}
              </p>
            </div>
          </div>
        </v-container>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.carousel-3d-container {
  overflow: initial !important;
  padding-top: 75px;
  margin: 89px auto !important;
}

.box {
  flex-direction: column;
}

.carousel-3d-slide {
  max-width: 518px;
  height: 328px;
  background: #ffffff;
  box-shadow: 0px 13px 43px rgba(75, 102, 135, 0.1);
  border-radius: 14px;
  flex-direction: column !important;
  overflow: initial !important;
  border: none;
}

.feed-back-row {
  display: flex;
  gap: 72px;
  align-items: center;
  left: 31px;
  position: relative;
}

.avatar {
  position: relative;
  top: -54px;
  height: 73px;
}

div#example {
  overflow: hidden;
}

#example {
  position: relative;
}

#example .backEffect {
  position: absolute;
  background: url("@/assets/dot pattern.png");
  position: absolute;
  width: 300px;
  height: 489px;
  background-repeat: no-repeat;
  top: 35px;
  background-size: cover;
}

@media(max-width:650px) {
  #example .backEffect {
    width: 106px;
  }
}
</style>
