<template>
  <v-container fluid class="v-home-section px-lg-16 px-md-1 px-xs-2">
    <img :class="$vuetify.rtl ? 'imgEffect-rtl' : 'imgEffect'" src="@/assets/Ellipse-LTR.svg" alt="" />
    <div class="">
      <v-row class="justify-center justify-md-space-between">
        <v-col cols="12" sm="10" md="5" lg="6" class="text-col d-flex align-center mb-xs-13 m-0"  >
          <div class="intro">
            <div class="text" data-aos="fade-up">
              <h1 class="text-h4 text-md-h3 font-weight-bold mb-2">
                {{this.pageCode.title}}
              </h1>
              <p class="gray6--text mb-16" v-html="pageCode.description">
              </p>
              <span class="hidden-xs-only"></span>
              <span class="hidden-xs-only"></span>
              <span class="hidden-xs-only"></span>
            </div>

            <router-link to="/free-trial" class="gray2--text ">
              <div v-ripple
                class="trial d-flex yellow3 align-center justify-center rounded-pill pa-2 font-weight-bold text-md-h6">
                {{ $t("Get a free trial") }}
                <div :class="$vuetify.rtl ? 'trial-icon-rtl' : 'trial-icon'">
                  <img src="@/assets/click.svg" alt="trial icon" />
                </div>
              </div>
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" sm="10" md="7" lg="6" class="img-col position-relative ma-md-0 ma-lg-0" data-aos="fade-up">
          <div class="home-image">
            <div class="back-effect d-flex">
              <img class="backImg" src="@/assets/Vector.svg" alt="" />
              <img v-lazy="$serverUrl +pageCode.image" alt="" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

export default {
  name: "LandingSection",
  props: {
    'pageCode': Object,
  },
};
</script>

<style scoped>
p {
  line-height: 1.9;
}
.text-col {
  height: 500px;
}
.img-col {
  height: 500px;
  margin-top: 50px;
}
.back-effect {
  position: relative;
}
.front_img {
    position: relative;
    left: 0;
    top: 65px;
    width: 100%;
    height: fit-content;
}
.back-effect img:nth-child(1) {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
}
@media (max-width: 450px) {
  .back-effect img:nth-child(1) {
    position: absolute;
    top: -92px;
    width: 115%;
    left: -213px;
    height: 488px;
    margin-top: 40px;
  }
  .text h1 {
    text-align: center;
  }
}
.back-effect img:nth-child(2) {
  position: absolute;
  top: 71px;
  width: 100%;
  left: 45px;
}
@media (max-width: 600px) {
  .back-effect img:nth-child(2) {
    left: -6px;
  }
}
h1 {
  max-width: 363px;
  line-height: 1.2 !important;
}
.intro {
  position: relative;
  height: fit-content;
  top: 50px;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(73px);
  backdrop-filter: blur(73px);
}
.imgEffect {
  position: absolute;
  left: 35px;
  top: 204px;
  width: 300px;
  height: 300px;
}
.imgEffect-rtl {
  position: absolute;
  right: 35px;
  top: 241px;
  width: 300px;
  height: 300px;
}
.text span:nth-of-type(1) {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fcbc14;
  top: -36px;
  left: 17%;
}
.v-application.v-application--is-rtl .text span:nth-of-type(1) {
  top: -49px;
  left: 74%;
}
.text span:nth-of-type(2) {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #25366f;
  top: -1px;
  left: 77%;
}
.v-application.v-application--is-rtl .text span:nth-of-type(2) {
  left: 35%;
}
.text span:nth-of-type(3) {
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #fcc430;
  top: 146px;
  left: 101%;
}
.v-application.v-application--is-rtl .text span:nth-of-type(3) {
  left: -13%;
}
.intro .text {
  max-width: 582px;
  position: relative;
}
.trial {
  position: relative;
  width: 300px;
  padding: 0px 38px 0 0px !important;
  height: 48px;
  cursor: pointer;
}
.trial-icon {
  position: absolute;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  right: 0px;
  background: #fff;
  border-radius: 50%;
  padding: 8px;
}
.trial-icon-rtl {
  position: relative;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  right: 6px;
  margin-right: 10px;
  background: #fff;
  border-radius: 50%;
  padding: 8px;
}
@media (max-width: 600px) {
  .trial-icon-rtl {
    right: 28px;
  }
}
.back-effect img {
  max-width: 629px;
  position: relative;
  bottom: -54px;
  left: -18px;
}
</style>
