import Vue from "vue";
import axios from "axios";

Vue.prototype.$http = axios;
Vue.prototype.$serverUrl = "https://qarat-api-v1.qaderhr.com/";

const lang = localStorage.getItem("language") ? localStorage.getItem("language") : "ar";

axios.defaults.baseURL = `${Vue.prototype.$serverUrl}api/${lang}/`
axios.defaults.headers.common["access-token"] = 'andqaratBflgp8NWi8nBDB92AjTUDb3jDzZw7LtkcyMY5kBC76KLkcKEzOqQdqarat'
axios.defaults.headers.common["Authorization"] = null

Vue.prototype.$website_loading = true;
Vue.prototype.$website_data = null;


export default {
    install(Vue) {
        Vue.prototype.$api = {

            async GET_METHOD(RESOURCE_NAME) {
                try {
                    const request = await axios.get(RESOURCE_NAME);
                    return request.data
                } catch (error) {
                    return console.log(error.message);
                }
            },
            async GET_WEBSITE_DATA() {
                Vue.prototype.$website_loading = true
                try {
                    const request = await axios.get('Home_api_url');
                    Vue.prototype.$website_loading = false
                    return Vue.prototype.$website_data = request.data.data
                } catch (error) {
                    return console.log(error.message);
                }
            },
            async POST_METHOD(RESOURCE_NAME, BODY) {
                try {
                    const request = await axios.post(RESOURCE_NAME, BODY);
                    return request.data
                } catch (error) {
                    return console.log(error);
                }
            },
            async DELETE_METHOD(RESOURCE_NAME, ID) {
                try {
                    const request = await axios.delete(RESOURCE_NAME + ID);
                    return request.data
                } catch (error) {
                    return console.log(error);
                }
            },
        }

    }
}
