<template>
  <v-container fluid id="features" class="benefits-section text-capitalize  white px-lg-16 px-md-1 px-xs-2 my-16">
    <img class="square-big" src="@/assets/square.svg" alt="" />
    <header class="ma-11 text-center" data-aos="fade-up">
      <h2 class="text-h4 font-weight-black ma-5">{{this.pageCode.title}}</h2>
      <p class="gray5--text font-weight-light text-h5">
        {{this.pageCode.description}}
      </p>
    </header>
    <v-row class="row-cards">

      <v-col v-for="data in $website_data.goal" :key="data.id" cols="12" sm="6" md="4" lg="4" data-aos="fade-down">
        <div class="card mt-7">
          <div class="icon-card">
            <img :src="$serverUrl + data.image" alt="" />
          </div>
          <div class="title text-h6 gray1--text font-weight-bold text-center">
            {{ data.title }}
          </div>
          <p class="text-subtitle-1 gray7--text font-weight-regular">
            {{ data.description }}
          </p>
        </div>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>



export default {
  name: "BenefitsSection",
  props: {
    'pageCode': Object,
  },

};
</script>

<style lang="scss" scoped>
.benefits-section {
  position: relative;
  margin-top: -29px;
  z-index: 200;
  overflow: hidden;
}

.square-big {
  position: absolute;
  left: 246px;
  z-index: -2;
}

header {
  line-height: 3.6;
}

p {
  max-width: 700px;
  line-height: 1.6;
  margin: auto;
  display: -webkit-box;
  //overflow: hidden;
  //-webkit-line-clamp: 3;
  //-webkit-box-orient: vertical;
  transition: 0.3s;
  padding-top: 15px;
}

.icon-card {
  width: 90px !important;
  height: 90px !important;
  display: flex;
  border-radius: 50%;
  background: #ffffff;
  margin: 4px auto 24px;
}

.icon-card img {
  height: 45px;
  width: 45px;
  margin: auto;
}

.card {
  width: 306px;
  margin: auto;
  height: fit-content;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(132, 132, 132, 0.07);
  border-radius: 23px;
}

.row-cards {
  .col-12:nth-of-type(1) {
    .icon-card {
      box-shadow: 0px 8px 38px rgba(27, 195, 0, 0.3);
    }
  }

  .col-12:nth-of-type(2) {
    .icon-card {
      box-shadow: 0px 8px 41px rgba(50, 74, 94, 0.22);
    }
  }

  .col-12:nth-of-type(3) {
    .icon-card {
      box-shadow: 0px 8px 55px rgba(255, 205, 29, 0.24);
    }
  }

  .col-12:nth-of-type(4) {
    .icon-card {
      box-shadow: 0px 8px 24px rgba(53, 95, 158, 0.1);
    }
  }

  .col-12:nth-of-type(5) {
    .icon-card {
      box-shadow: 0px 8px 24px rgba(53, 95, 158, 0.1);
    }
  }

  .col-12:nth-of-type(6) {
    .icon-card {
      box-shadow: 0px 8px 55px rgba(255, 205, 29, 0.24);
    }
  }
}
</style>
